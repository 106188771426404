<template>

  <div style="position: fixed; right:20px; top:10px;">

    <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="DelEvent()">
      <label class="delBom">删除BOM</label>
    </a-popconfirm>

    <a-button type="primary" @click="SavePrice">保存已经录入的报价</a-button>
  </div>

  <a-spin class="loadingClass" v-if="dataValue.loading === true" :tip="dataValue.loadingText"></a-spin>
  <div class="tj" v-if="!dataValue.loading">
    <div class="n">
      <div>总条数：</div>
      <div>{{dataValue.analyse['zsm']}}</div>
    </div>
    <div class="n">
      <div>冷门型号：</div>
      <div>{{dataValue.analyse['lmxhsm']}}</div>
    </div>
    <div class="n">
      <div>热门型号：</div>
      <div>{{dataValue.analyse['rmxhsm']}}</div>
    </div>
    <div class="p">
      <div>金额最大：</div>
      <div>{{dataValue.analyse['jgzdxhbjName']}}</div>
      <div>{{dataValue.analyse['jgzdxhbj']}}</div>
    </div>
    <div class="p">
      <div>数量最多：</div>
      <div>{{dataValue.analyse['slzdxhbjName']}}</div>
      <div>{{dataValue.analyse['slzdxhbj']}}</div>
    </div>
  </div>
  <div class="tj" v-if="!dataValue.loading">
    <div class="n">
      <div>估价记录：</div>
      <div>{{dataValue.analyse['ybjsm']}}</div>
    </div>
    <div class="n">
      <div>通用型号：</div>
      <div>{{dataValue.analyse['tyxhsm']}}</div>
    </div>
    <div class="n">
      <div>网红型号：</div>
      <div>{{dataValue.analyse['whxhsm']}}</div>
    </div>
    <div class="p">
      <div>交期最长：</div>
      <div>{{dataValue.analyse['jqzcxhbjName']}}</div>
      <div>{{dataValue.analyse['jqzcxhbj']}}</div>
    </div>
    <div class="p">
      <div>总报价：</div>
      <div></div>
      <div>{{dataValue.analyse['zbj']}} {{ dataValue.moneyTypeName }}</div>
    </div>
  </div>


  <div
      style="clear:both;height: 100%; padding-right: 15px!important; overflow-y: auto;overflow-x: hidden;background-color: #ffffff;"
      v-if="dataValue.loading === false">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>选中</th>
        <th>型号</th>
        <th>类型</th>
        <th>类星</th>
        <th>厂商</th>
        <th>批号</th>
        <th>数量</th>
        <th>价格</th>
        <th>小计</th>
        <th>报价均价</th>
        <th>系统估价</th>
        <th colspan="2">IC7均价</th>
        <th class="nobr w1">货币</th>
        <th class="red">报价</th>
        <th>分析</th>
      </tr>
      <tr v-for="aRow in dataValue.data" :style="selectRowColor(aRow['indexNumber'])"
          @click="selectRowEvent(aRow['indexNumber'])">
        <td class="w38">{{ aRow['indexNumber'] }}</td>
        <td class="gou">{{ aRow['checked'] === true ? '√' : '' }}</td>
        <td>{{ aRow['modelName'] }}</td>
        <td class="min">{{ aRow['modelType'] }}</td>
        <td class="min">{{ aRow['modelStar'] }}</td>
        <td class="center">{{ aRow['manufacturerName'] }}</td>
        <td class="center">{{ aRow['batchName'] }}</td>
        <td class="number">{{ aRow['number'] }}</td>
        <td :style="tools.priceColor(aRow['price'])">{{ tools.formatPrice(aRow['price'], 5) }}</td>
        <td :style="tools.priceColor(aRow['allPrice'])">{{ tools.formatPrice(aRow['allPrice'], 2) }}</td>
        <td class="price">


          <a-popover :title="'30天内共有'+ aRow['historyQuotePriceList'].length +'条报价记录'" placement="topRight"
                     v-if="aRow['historyQuotePriceList'].length > 0">
            <template #content>
              <table class="ahuiTable2">
                <tr v-for="aPrice in aRow['historyQuotePriceList']">
                  <td class="money">{{ tools.formatPrice(aPrice['quotedPrice'], 5) }}</td>
                  <td class="center">{{ aPrice['moneyTypeName'] }}</td>
                  <td>{{ aPrice['quoteTime'] }}</td>
                </tr>
              </table>
            </template>
            <span type="primary" class="pop" :style="tools.priceColor(aRow['historyQuoteAveragePrice'])">{{
                tools.formatPrice(aRow['historyQuoteAveragePrice'], 5)
              }}</span>
          </a-popover>


        </td>
        <td class="price" :style="tools.priceColor(aRow['assessPrice'])">{{
            tools.formatPrice(aRow['assessPrice'], 5)
          }}
        </td>
        <td class="price">
          <a-popover :title="'7天内共有'+ aRow['historyIC7PriceList'].length +'条报价记录'" placement="bottomLeft"
                     v-if="aRow['historyIC7PriceList'].length > 0">
            <template #content>
              <table class="ahuiTable2">
                <tr v-for="aPrice in aRow['historyIC7PriceList']">
                  <td>{{ aPrice['toCompany'] }} ({{ aPrice['replyMobile'] }})</td>
                  <td>{{ aPrice['modelName'] }}</td>
                  <td class="money" :style="tools.priceColor(aRow['replyMoney'])">{{ aPrice['replyMoneyTypeName'] }}
                    {{ tools.formatPrice(aPrice['replyMoney'], 5) }}
                  </td>
                  <td>{{ aPrice['replyTime'] }}</td>
                </tr>
              </table>
            </template>
            <span type="primary" class="pop" :style="tools.priceColor(aRow['historyIC7AveragePrice'])">{{
                tools.formatPrice(aRow['historyIC7AveragePrice'], 5)
              }}</span>
          </a-popover>

        </td>
        <td class="icXJ" @click="IC7Open(aRow['stockNodeId'], aRow['modelName'], aRow['number']);">
          <message-outlined/>
        </td>
        <td class="center nobr">{{ aRow['moneyTypeName'] }}</td>
        <td style="background-color: #ffffff;padding: 0;width: 100px">
          <a-input placeholder="请录入报价" v-inputPrice v-model:value="aRow['quotedPrice']" class="inputV"/>
        </td>
        <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
      </tr>

    </table>
  </div>

  <div class="reply" ref='replayModal'>
    <a-modal v-model:visible="IC7OpenData.visible" :title="IC7OpenData.title"
             :destroyOnClose="true"
             :keyboard="false"
             :maskClosable="false"
             :centered="true"
             :width="IC7OpenData.width"
             :bodyStyle="IC7OpenData.bodyStyle"
             :getContainer='()=>$refs.replayModal'
             :footer="null">
      <getSupplierInfo :stock_node_id="IC7OpenData.stockNodeId" :model_name="IC7OpenData.modelName"
                       :request_number="IC7OpenData.requestNumber" @closeModal1="IC7OpenEnd"
      ></getSupplierInfo>
    </a-modal>
  </div>

  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import ahuiTable from "@/components/table";
import getSupplierInfo from "@/view/order/get_supplier_info";
import tools from "@/tools/small_methods";
import biModel from "@/view/bi/bi_model"

export default {
  name : "stock.one.detail",
  props : [ 'stock_batch_id', 'money_type_name' ],
  components : { ahuiTable, getSupplierInfo, biModel },
  emits : [ 'detailClose' ],
  setup(props, { emit }) {

    // A - IC7（开始）

    let IC7OpenData = reactive({
      "title" : "IC7询价",
      "visible" : false,
      "width" : 1200,
      "maxHeight" : 600,
      "bodyStyle" : {
        "height" : "500px"
      },
      stockNodeId : 0,
      modelName : "",
      requestNumber : 0,
    })


    const IC7Open = (stockNodeId, modelName, requestNumber) => {

      IC7OpenData.visible = true;
      IC7OpenData.stockNodeId = stockNodeId;
      IC7OpenData.modelName = modelName;
      IC7OpenData.requestNumber = requestNumber;
      IC7OpenData.title = "IC7询价 - " + modelName
    }

    const IC7OpenEnd = () => {
      IC7OpenData.visible = false;
    }


    // A - IC7（结束）


    let dataValue = reactive({
      bi:{
        show: false,
        modelName : ""
      },
      loading : false,
      loadingText : "正在加载数据和估价 ...",
      stockBatchId : props.stock_batch_id,
      moneyTypeName : props.money_type_name.toLowerCase() === "usd" ? "USD" : "RMB",
      "analyse": null,
      "data" : []
    })

    // watch(() => props, (newProps) => {
    //   GoEvent(newProps.stock_batch_id);
    // }, { deep : true, immediate : true });

    function GoEvent() {

      dataValue.loading = true;
      dataValue.loadingText = "";

      let dataInput = {
        "stockBatchId" : dataValue.stockBatchId,
        "showPrice" : 1,
        "isDownload" : 0
      }
      httpRequest.post('/admin/stock/list.batch.detail', dataInput)
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.analyse = res.data.analyse;
              dataValue.data = res.data.list;
            }

            dataValue.loading = false;
          })
    }

    GoEvent();

    // 选择其中一行
    const selectRowEvent = (rowId) => {
      dataValue.selectRow = rowId;
    }
    let selectRowColor = (rowId) => {
      return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
    }

    let allEvent = {

      bi : {

        open : (row)=>{
          // console.log(row['modelName'])
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },

        close: ()=>{
          dataValue.bi.show = false;
        }

      }
    }


    const DelEvent = () => {

      let dataInput = {
        "stockBatchId" : dataValue.stockBatchId
      }
      httpRequest.post('/admin/stock/del', dataInput)
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              emit('detailClose');
            }
          })
    }

    const SavePrice = () => {

      dataValue.loading = true;
      dataValue.loadingText = "正在保存记录 ... ";

      dataValue.data.forEach((row) => {

        if (row['quotedPrice'] !== undefined && row['quotedPrice'] !== "") {
          let dataInput = {
            "stockNodeId" : parseInt(row['stockNodeId']),
            "quotedPrice" : parseFloat(row['quotedPrice']).toString()
          }
          httpRequest.post('/admin/stock/quoted.price', dataInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                }

                dataValue.loading = false;
              })
        }
      });
      // GoEvent();
    }

    return {
      dataValue,
      IC7OpenData,
      IC7Open,
      IC7OpenEnd,
      tools,
      selectRowEvent,
      selectRowColor,
      SavePrice,
      DelEvent,
      allEvent
    }

  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
:deep( .ant-modal-body ) {
  padding: 5px;
  height: 100%;
  overflow: auto;
}
.inputV { border: 0;width: 100%; color: red;font-size: 12px !important; text-align: right;}
.inputV::placeholder { font-size: 10px !important; color: #dddddd; }
.price { width: 80px; }
.pop { border-bottom: 1px dotted rgb(0, 0, 255); }
.icXJ { width: 29px; text-align: center; font-size: 14px; color: #666666; cursor: pointer; }
.icXJ:hover { color: #225DDC; font-size: 16px; }
.delBom { margin-right: 50px; cursor: pointer; }
.delBom:hover { color: red;}
.tj {width: 100%; clear: both; margin-bottom: 10px;float: left; line-height: 50px; }
.tj > div {border: 1px solid #cccccc;float: left; margin: 0 1% 0 1%; border-radius: 3px; background-color: #ffffff; }
.tj .n{ width: 10%;  }
.tj .n > div:nth-child(1) {float: left; margin: 0 1px 0 20px;font-weight: bold; color:#1a5f91;}
.tj .n > div:nth-child(2) {color:#1a5f91;;}
.tj .p{ width: 29.3%;  }
.tj .p > div:nth-child(1) {float: left; margin: 0 1px 0 20px;font-weight: bold; color:#1a5f91;}
.tj .p > div:nth-child(2) {color:#555555; float: left; width: 150px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
.tj .p > div:nth-child(3) {color:#225DDC;; float: right; margin-right: 15px; }
.gou{ text-align:center; font-weight:bold; color:red; font-family:"Songti SC"; width:30px; }
</style>
