<template>
  <div>
    <div class="aNode">
      <span>型号名称：</span>
      <span>{{ data.modelName }}</span>
    </div>
    <div class="aNode">
      <span>请求数：</span>
      <span>{{ data.askCompanySize }}家</span>
    </div>
    <div class="aNode">
      <span>过期时间(单位小时)：</span>
      <span>
          <a-input style="width:80px;text-align:right;padding-right: 2px;" :max-length="5" type="number"
                   v-model:value="data.endTimeSize"/>
      </span>
    </div>
    <div class="aNode">
      <span>需求量：</span>
      <span>
        <a-tooltip>
          <template #title>传递给用户的需求数量</template>
          <a-input style="width:130px;text-align: right;padding-right: 2px;" :maxlength="10"
                   v-model:value="data.requestNumber" type="number"/>
        </a-tooltip>
      </span>
    </div>
    <div class="aNode">
      <span>我的手机号：</span>
      <span>
        <a-tooltip>
          <template #title>此号码会传递给用户。</template>
          <a-input style="width:130px;text-align: right;" v-model:value="data.userInfo['mobile']"/>
        </a-tooltip>
      </span>
    </div>
  </div>
  <div class="buttons">
    <a-button type="primary" :disabled="data.buttonDisabled" @click="saveInfo">{{ data.buttonText }}</a-button>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";

export default {
  name : "send_ask",
  props : [ 'model_name', 'request_number', 'send_to_suppliers' ],
  emits : [ 'closeModal' ],
  setup(props, { emit }) {

    let data = reactive({
          "modelName" : props.model_name,
          "requestNumber" : props.request_number,
          "askCompanySize" : props.send_to_suppliers.length,
          "endTimeSize" : "12",
          "sendToSuppliers" : props.send_to_suppliers,
          "buttonDisabled" : false,
          "buttonText" : "发送询价",
          "userInfo" : {}
        })
    ;

    // 获取手机号码
    proxy.postEIC('/human/employee/get.info', {})
        .then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            data.userInfo = res.data;
            // data.myTel = res.data.mobile;
          }
        })

    // 保存手机号
    const saveMobile = () => {
      proxy.postEIC('/human/employee/set.mobile', {
        "newMobile" : data.userInfo.mobile
      }).then((res) => {
        if (res.code !== 200) {
          message.error(res.message);
        }
      })
    }


    // 保存记录
    const saveInfo = () => {

      if (parseInt(data.endTimeSize) <= 0) {
        message.error("过期时间必须大于0")
        return
      }

      if (parseInt(data.requestNumber) <= 0) {
        message.error("需求量必须大于0")
        return
      }


      data.buttonDisabled = true
      data.buttonText = "询价中 ... "

      saveMobile();

      for (let i = 0; i < data.sendToSuppliers.length; i++) {

        let dataInput = {
          "askUserId" : data.userInfo['employeeId'],
          "askUserName" : data.userInfo['employeeName'],
          "askUserMobile" : data.userInfo.mobile,
          "askCompany" : "芯链芯",
          "toCompany" : data.sendToSuppliers[i].companyName,
          "toCompanyType" : 2,
          "toUserName" : "先生/小姐",
          "toMobile" : data.sendToSuppliers[i].tel,
          "toEmail" : "",
          "modelName" : data.sendToSuppliers[i].model,
          "manufacturerName" : data.sendToSuppliers[i].manufacturer,
          "batchName" : data.sendToSuppliers[i].batch,
          "quoteNumber" : parseInt(data.requestNumber),
          "remark" : "",
          "endTimeSize" : parseInt(data.endTimeSize)
        }
        httpRequest.post('/ic7/ask', dataInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              }
            })
      }

      message.info(data.sendToSuppliers.length + "条记录已经发送结束，请勿重复操作。")
      emit("closeModal")
    }


    return {
      data,
      saveInfo
    }
  }
}
</script>

<style scoped>
.aNode { border-bottom: 1px solid #eeeeee; height: 50px; line-height: 50px; }
.aNode > span:nth-child(2) { float: right; }
.buttons { text-align: center;margin-top: 40px; height: 50px }
</style>