<template>

  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在查询数据 ..."></a-spin>
  <div class="goPost">
    <div>
      <label class="tPopInfo">("<span class="pop2">已询价</span>"标记代表3日内有过询价)</label>
      <label>您已经选择了<span class="red">{{ dataValue.checkedCount }}</span>条记录</label>
      <a-button type="primary" v-if="dataValue.checkedCount === 0" disabled>点击发出询价</a-button>
      <a-button type="primary" v-if="dataValue.checkedCount > 0" @click="ic7Send">点击发出询价</a-button>
    </div>
  </div>

  <table class="ic7Table" v-if="dataValue.httpData.rowCount > 0">
    <tr v-for="aNode in dataValue.httpData.models" @click="selectRow(aNode.rowIndex)">
      <td>
        <div class="selectNode">
          <check-outlined v-if="aNode.checked"/>
          <border-outlined v-if="!aNode.checked" style="color: #cccccc;"/>
        </div>
      </td>
      <td>{{ aNode.rowIndex }}</td>
      <td>
        <a-popover :title="aNode.companyName" placement="topLeft">
          <template #content>
            <p>电话:{{ aNode.tel }}</p>
            <p>年限：{{ aNode.year }}年</p>
            <p>标记：<label v-html="aNode.labelImg"></label></p>
            <p>
              <a-button @click="thisEvent.toEC.sendOne(aNode)">把公司信息添加到EC</a-button>
            </p>
          </template>
          <span class="pop" type="primary">{{ aNode.companyName }}</span>
        </a-popover>
      </td>
      <td class="nobr center red pop2">{{ aNode.haveSend }}</td>
      <td class="nobr center">{{ aNode.year }}年</td>
      <td class="logImg"><label v-html="aNode.labelImg"></label></td>
      <td class="nobr" style="width:60px;">{{ aNode.manufacturer }}</td>
      <td class="nobr" style="width:60px;">{{ aNode.batch }}</td>
      <td class="nobr number">{{ aNode.number }}</td>
      <td>{{ aNode.remark }}</td>
    </tr>
  </table>


  <div class="reply" ref='replayModal'>

    <a-modal v-model:visible="dataValue.ic7Send.visible" :title="dataValue.ic7Send.title"
             :destroyOnClose="true"
             :keyboard="false"
             :maskClosable="false"
             :centered="true"
             :width="dataValue.ic7Send.width"
             :footer="null">
      <send-ask :model_name="dataValue.modelName" :request_number="dataValue.requestNumber"
                :send_to_suppliers="dataValue.sendToSuppliers" @closeModal="ic7SendEnd"></send-ask>
    </a-modal>

  </div>
</template>

<script>


import {reactive, watch} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import Label from "@/view/_temp/label";
import sendAsk from "@/view/order/send_ask"

export default {
  name : "send_message",
  components : { Label, sendAsk },
  props : [ 'stock_node_id', 'model_name', 'request_number' ],
  emits : [ 'closeModal1' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : false,
      stockNodeId : 0,
      modelName : "",
      requestNumber : props.request_number,
      sendToSuppliers : [ 1, 2 ],
      checkedCount : 0,
      ic7Send : {
        "title" : "发送询价请求",
        "visible" : false,
        "width" : 300,
        "maxHeight" : 400
      },
      httpData : {
        rowCount : 0,
        models : [
          {
            "rowIndex" : 1,
            "companyName" : "",
            "labelImg" : [],
            "year" : 1,
            "address" : [],
            "model" : "xxx",
            "manufacturer" : "xxx",
            "envelop" : "xxx",
            "batch" : "xxx",
            "number" : 10000,
            "remark" : "xxx",
            "userName" : "",
            "tel" : "xxx"
          }
        ]
      }
    })

    watch(() => props, (newProps) => {
      dataValue.stockNodeId = newProps.stock_node_id;
      dataValue.modelName = newProps.model_name;
    }, { deep : true, immediate : true });

    function toRequest() {


      dataValue.loading = true;
      let dataInput = {
        "modelName" : dataValue.modelName,
        "pageNumber" : 1
      }
      httpRequest.post('/admin/ic7/get.model.supplier', dataInput)
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              // console.log(res)
              dataValue.httpData.rowCount = res.data.rowCount;
              dataValue.httpData.models = res.data.models;

              for (let i = 0; i < dataValue.httpData.models.length; i++) {
                dataValue.httpData.models[i].checked = false;
              }
            }
            dataValue.loading = false;
          })
    }

    toRequest();


    // 选择其中的一行
    const selectRow = (rowIndex) => {
      dataValue.httpData.models[rowIndex - 1].checked = !dataValue.httpData.models[rowIndex - 1].checked

      dataValue.checkedCount = 0;
      for (let i = 0; i < dataValue.httpData.models.length; i++) {
        if (dataValue.httpData.models[i].checked === true) dataValue.checkedCount++;
      }
    };

    // 发送询价
    const ic7Send = () => {

      dataValue.sendToSuppliers = [];

      for (let i = 0; i < dataValue.httpData.models.length; i++) {
        if (dataValue.httpData.models[i].checked === true) dataValue.sendToSuppliers.unshift(dataValue.httpData.models[i]);
      }

      dataValue.ic7Send.visible = true;
    }

    const ic7SendEnd = () => {
      emit("closeModal1")
      dataValue.ic7Send.visible = false;
    }

    let thisEvent = {

      toEC : {

        sendBatch : () => {

          // dataValue.supplierInfo.models.forEach((node) => {
          //   if (node['checked']) thisEvent.toEC.sendOne(node);
          // });
        },

        sendOne : (node) => {

          let dataInput = {
            "linkModel" : node['model'],
            "companyName" : node['companyName'],
            "address" : node['address'],
            "remark" : node['remark'],
            "qq" : node['qq'],
            "tel" : node['telList'],
            "mobile" : node['mobileList']
          }

          httpRequest.post('/admin/ec/add.company', dataInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message, 2);
                } else {

                  let addUserTrueSize = res.data['addUserTrueSize'];
                  let addUserFalseSize = res.data['addUserFalseSize'];
                  let addUserFalseInfo = res.data['addUserFalseInfo'];

                  if (addUserFalseSize === 0) {
                    message.info("添加公司\""+ dataInput.companyName +"\"成功，其中包括" + addUserTrueSize + "个联系人信息",1);
                  } else {
                    message.error("添加公司\""+ dataInput.companyName +"\"有" + addUserFalseSize + "条失败记录：" + addUserFalseInfo, 1);
                  }
                }
              })
        }
      }

    }


    return {
      dataValue,
      selectRow,
      ic7Send,
      ic7SendEnd,
      thisEvent
    }
  }
}
</script>

<style scoped>
.goPost { position: relative; float: right; right: 650px; bottom: 47px; }
.goPost > div { position: fixed; width: 600px; float: right; text-align: right;}
.goPost > div label { margin-right: 20px; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.ic7Table { border-collapse: collapse; margin: 15px 10px 35px 10px;width: calc(100% - 20px); border-top: 0.01em solid #cccccc;
  border-spacing: 0; }
.ic7Table tr:nth-child(2n+1) { background-color: #ebefee; }
.ic7Table tr:hover { background-color: #fefebb; cursor: default;}
.ic7Table td { border-bottom: 0.01em solid #cccccc; font-size: 12px;}
.ic7Table td { height: 35px; padding: 0 5px 0 5px; }
.ic7Table .center { text-align: center; }
.ic7Table .number { color: #1a5f91; text-align: center; }
.ic7Table .logImg { opacity: 0.2; }
.ic7Table .nobr { white-space: nowrap; }
.ic7Table .selectNode { text-align: center; width: 30px; color: red; }
.ic7Table .red { color: red; }
.pop { border-bottom: 1px dotted rgb(0, 0, 255); }
.pop2 { width: 20px; font-weight: bold; }
.tPopInfo { font-size: 12px; color: #999999; padding: 0 50px 5px 0; }
</style>