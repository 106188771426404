<!--<ahuiTable :data="dataValue.data" :columns="dataValue.columns" :index="dataValue.index"-->
<!--           :page="dataValue.pages.page" :number_of_page="dataValue.pages.numberOfPage"-->
<!--           :all_page="dataValue.pages.allPage" :all_count="dataValue.pages.allCount"-->
<!--           @clickSetEvent="setEvent" @clickToPage="setPage"-->
<!--/>-->


<!--openChildWindow.value.openWindow( "这个是一个测试窗口", "https://www.hqew.com", 1300, 800 );-->

<template>

  <table class="aTable">
    <tr>
      <th v-for="aCol in columns">{{ aCol.title }}</th>
    </tr>
    <tr v-for="aNode in data">
      <template v-for="aCol in columns">
        <td :style="'width:'+ aCol.width +'px; color:'+ myTable.setColor(aCol.format) +'; text-align:'+ aCol.align +''">
          <template v-if="!aCol.urlFunc">
            {{ myTable.formatValue(myTable.getNodeValue(aNode, aCol.dataIndex), aCol.format) }}
          </template>
          <template v-if="aCol.urlFunc">
            <a @click="myTable.toParent(aCol.urlFunc, aNode, index);">{{ aCol.urlTxt }}</a>
          </template>
        </td>
      </template>
    </tr>
    <tr>

      <!-- 翻页开始 -->
      <td class="pages" :colspan="columns.length" v-if="childData.pages.page > 0">
        <div class="toRight">

          <div class="info">每页{{ childData.pages.numberOfPage }}条，共{{ childData.pages.page }}/{{
              childData.pages.allPage
            }}页，{{ childData.pages.allCount }}条
          </div>


          <div @click="myTable.toPage(1)" class="page default" v-if="childData.pages.page > 5">1</div>
          <div class="page ddd" v-if="childData.pages.page > 6">...</div>

          <template v-for="i in childData.pages.allPage">
            <div v-if="i > childData.pages.page -5 && i < childData.pages.page + 5 && i !== childData.pages.page"
                 class="page default" @click="myTable.toPage(i)">{{ i }} <!--一般页-->
            </div>
            <div v-if="i === childData.pages.page" class="page click">{{ i }}</div> <!--选中-->
          </template>

          <div class="page ddd" v-if="childData.pages.page < childData.pages.allPage - 5">...</div>
          <div class="page default" @click="myTable.toPage(childData.pages.allPage)"
               v-if="childData.pages.page < childData.pages.allPage - 4">{{
              childData.pages.allPage
            }}
          </div>

        </div>
      </td>

      <!-- 翻页结束 -->
    </tr>
  </table>


</template>
<script>

import {reactive, watch} from "vue";

export default {
  name : "ahuiTable",
  props : [ 'data', 'columns', 'index', 'page', 'number_of_page', 'all_page', 'all_count' ],
  setup(props, { emit }) {


    let childData = reactive({
      "pages" : {
        "page" : 1,
        "numberOfPage" : 20,
        "allPage" : 1,
        "allCount" : 0,
      },
      "data" : [],
      "index" : [ "" ],
      "columns" : [ {
        title : '标题',
        dataIndex : '对应的数据列',
        width : 100,
        align : 'center',
        urlFunc : 'toPage;',
        format : 'price',
        urlTxt : '选择'
      } ]
    })

    watch(() => props, (newProps) => {
      childData.data = newProps.data;
      childData.columns = newProps.columns;
      childData.index = newProps.index;
      childData.pages.page = newProps.page <= newProps.all_page ? newProps.page : newProps.all_page;
      childData.pages.numberOfPage = newProps.number_of_page;
      childData.pages.allPage = newProps.all_page;
      childData.pages.allCount = newProps.all_count;

    }, { deep : true, immediate : true });

    let myTable = {

      // 判断是否有多级节点
      getNodeValue : function (aNodeObj, dataIndex) {
        for (let i = 0; i < dataIndex.split('.').length; i++) aNodeObj = aNodeObj[dataIndex.split('.')[i]]
        return aNodeObj;
      },

      // 格式化节点
      formatValue : function (value, formatType) {
        switch (formatType) {
          case "price":
            return parseFloat(value).toFixed(5);
          case "money":
            return parseFloat(value).toFixed(2);
          default:
            return value;
        }
      },

      // 设置颜色
      setColor : function (formatType) {
        switch (formatType) {
          case "price":
            return "#225DDC";
          case "money":
            return "#225DDC";
          case "number":
            return "#1a5f91";
          default:
            return "#333333";
        }
      },

      //向父节点传递按钮事件
      toParent : function (functionName, aNode, index) {

        let args = []
        for (let i = 0; i < index.length; i++) {
          args.push(aNode[index[i]])
        }
        emit(functionName, ...args);
      },

      // 翻页
      toPage : function (pageId) {
        // childData.pages.page = pageId
        emit('clickToPage', pageId);
      }
    }


    return {
      childData,
      myTable
    }
  }
}
</script>

<style scoped>
.aTable {
  border-collapse: collapse;
  margin: 5px 10px 5px 10px;
  width: calc(100% - 20px);
  border-top: 0.01em solid #a8a6a7;
  border-spacing: 0;
  border-left: 0.01em solid #a8a6a7;
}
.aTable tr:nth-child(2n+1) {
  background-color: #ebefee;
}
.aTable tr:hover {
  background-color: #fefebb;
  cursor: default;
}
.aTable th, td {
  border-right: 0.01em solid #a8a6a7;
  border-bottom: 0.01em solid #a8a6a7;
  font-size: 12px;
}
.aTable th {
  text-align: center;
  background-color: #e6e4d7;
  color: #000000;
  height: 30px;
  padding: 0 5px 0 5px;
  position: relative;
  white-space: nowrap;
}
.aTable td {
  height: 28px;
  padding: 0 5px 0 5px;
}
.pages {
  background-color: #ffffff;
  line-height: 33px;
  text-align: right;
  height: 33px !important;
}
.pages .toRight {
  float: right;
}
.pages .toRight .page {
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  padding: 0 5px 0 5px;
  text-align: center;
  float: left;
  margin: 3px;
  font-size: 12px;
  border-radius: 3px;
}

.pages .toRight .default {
  border: 1px solid #cccccc;
  cursor: pointer;
}
.pages .toRight .default:hover {
  border: 1px solid dodgerblue;
  color: dodgerblue;
}

.pages .toRight .click {
  border: 1px solid #ffffff;
  color: red;
}

.pages .toRight .ddd {
  border: 1px solid #ffffff;
  color: #cccccc;
}
.pages .toRight .info {
  float: left;
  padding: 0;
  margin: 0 15px 0 0;
  height: 20px;
  line-height: 30px;
  color: #999999;
}
</style>